<template>
  <div id="deliverySite" v-loading="loading">
    <!-- 收件人 -->
    <div class="con">
      <!-- 新增地址 -->

      <div class="Newaddress">
        <div @click="openAddAddressWin" class="add">
          {{ $fanyi('新增地址') }}
        </div>
      </div>
      <div class="opt" :class="{ default: item.acquiescent }" :style="(index + 1) % 3 == 0 ? 'margin-right:0px' : ''"
        v-for="(item, index) in consignee" :key="index">
        <span v-if="item.acquiescent" class="acquiescentDivContainer">
          {{ $fanyi('默认地址') }}
        </span>
        <div class="rowAndCenter" style="margin-bottom:15px;justify-content: space-between">
          <h1>{{ $fanyi('地址') }}{{ index + 1 }}</h1>

          <!-- 操作 -->
          <div class="btn">
            <button @click="edit(item)">{{ $fanyi('编辑') }}</button>
          </div>
        </div>
        <p>
          <label for="">{{ $fanyi('收件人') }}：</label>
          <span :title="item.contacts">{{ item.contacts }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi('邮编') }}:</label>
          <span :title="item.zip_code">{{ item.zip_code }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi('州') }}：</label>
          <span :title="item.state">{{ item.state }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi('城市') }}：</label>
          <span :title="item.email">{{ item.city }}</span>
        </p>

        <p class="address">
          <label for="">{{ $fanyi('地址') }}：</label>
          <span :title="item.address">{{ item.address }}</span>
        </p>
        <p>
          <label for="">{{ $fanyi('手机') }}：</label>
          <span :title="item.mobile">{{ item.mobile }}</span>
        </p>
        <!--        <p>-->
        <!--          <label for="">{{ $fanyi("电话") }}：</label>-->
        <!--          <span :title="item.tel">{{ item.tel }}</span>-->
        <!--        </p>-->
        <p>
          <label for="">{{ $fanyi('邮箱') }}：</label>
          <span :title="item.email">{{ item.email }}</span>
        </p>
      </div>

    </div>

    <!-- 添加和 修改 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="610px" :before-close="handleClose">
      <div class="formBox">
        <el-form ref="form" :model="form" :rules="type === 1 ? newRules : rules" label-width="100px" :key="key">

          <el-form-item :label="$fanyi('收件人') + '：'" prop="contacts" v-show="type === 1">
            <el-input size="mini" v-model="form.contacts" oninput="this.value"
              :placeholder="$fanyi('请填写收件人')"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('邮编') + '：'" prop="zip_code">
            <el-input size="mini" v-model="form.zip_code" :placeholder="$fanyi('请输入邮编')" maxlength="5"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('州') + '：'" prop="state">
            <el-select class="xuanZeGuoJia" name="" filterable size="mini" style="width: 380px" v-model="form.state"
              :placeholder="$fanyi('请选择地区')">
              <el-option :label="item" :value="item" v-for="(item, index) in $imdata.provincesList" :key="item + index">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$fanyi('城市') + '：'" prop="city">
            <el-input v-model="form.city" oninput="this.value" :placeholder="$fanyi('请输入城市')">
            </el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('地址') + '：'" prop="address">
            <el-input v-model="form.address" oninput="this.value" :placeholder="$fanyi('请输入收货地址')">
            </el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('全名') + '：'" prop="contacts" v-show="type === 2">
            <el-input size="mini" v-model="form.contacts" oninput="this.value">
            </el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('手机') + '：'" prop="mobile">
            <div class="dianhu">
              <!-- <span class="quhao">
                <el-select v-model="country">
                  <el-option v-for="item in Areacodelist" :key="item.sx" :label="item.value" :value="item.value">
                    {{ $store.state.languagetype == "English" ? item.sx + ' ' + item.value : item.key + ' ' +
                      item.value }}
                  </el-option>
                </el-select>
              </span>  -->

              <el-input size="mini" class="tel" v-model="form.mobile" oninput="this.value"
                :placeholder="$fanyi('请输入电话')">
              </el-input>
            </div>
          </el-form-item>
          <el-form-item :label="$fanyi('邮箱') + '：'" prop="email">
            <el-input size="mini" v-model="form.email" oninput="this.value" :placeholder="$fanyi('请输入邮箱')"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('SSN') + '：'" prop="cnpj" v-show="type === 2">
            <el-input size="mini" v-model="form.cnpj" maxlength="9"></el-input>
          </el-form-item>
          <el-form-item :label="$fanyi('设为默认地址') + '：'">
            <el-switch v-model="form.isAcquiescent"> </el-switch>
            <!-- <el-checkbox v-model="form.acquiescent">默认</el-checkbox> -->
          </el-form-item>
        </el-form>
        <div class="btn">
          <!-- 新增 -->
          <p v-if="isAdd" class="edit">
            <button class="save" style="margin-right: 20px;" @click="openAddAddressWin">
              {{ $fanyi('重置') }}
            </button>
            <button class="del" @click="$fun.debounce(useraddressAdd, 1000)">
              {{ $fanyi('添加') }}
            </button>
          </p>
          <!-- 删除 -->
          <p class="edit" v-else>
            <button class="save" style="margin-right: 20px;" @click="useraddressDelete">
              {{ $fanyi('删除') }}
            </button>
            <button @click="useraddressUpdate" class="del">
              {{ $fanyi('保存') }}
            </button>
          </p>

          <!-- <button>保存</button> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Areacode from '../../../../utlis/mobilephoneareacode.js'
export default {
  data() {

    return {
      type: 1,
      consignee: [],
      importer: [],
      dialogVisible: false,
      Areacodelist: Areacode,// 区号列表
      form: {
        zip_code: '',
      },
      title: '',
      isAdd: false,
      country: '+001',// 区号

      key: 'fsddsfa',
      newRules: {
        zip_code: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
          {
            pattern: /^(\w){5}$/,
            message: this.$fanyi('邮编输入错误'),
            trigger: 'blur',
          },
        ],
        contacts: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        address: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },

        ],
        city: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        state: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        tel: [
          {
            required: true,
            message: this.$fanyi('不能为空'),
            trigger: 'blur,change',
          },
        ],
        email: [
          {
            required: true,
            message: this.$fanyi('不能为空'),
            trigger: 'blur',
          },

        ],
        company: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
      },
      rules: {
        zip_code: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
          {
            pattern: /^(\w){5}$/,
            message: this.$fanyi('邮编输入错误'),
            trigger: 'blur',
          },
        ],
        address: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        contacts: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        mobile: [
          // { required: true, message: this.$fanyi("不能为空"), trigger: "blur" },
          {
            required: true,
            pattern: /^(\w){10}$/,
            message: this.$fanyi('手机号输入错误'),
          },
        ],
        city: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        state: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        tel: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
        email: [
          {
            required: true,
            message: this.$fanyi('请输入正确的邮箱地址'),
            trigger: 'blur',
          },


        ],
        cnpj: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
          {
            pattern: /^(\w){9}$/,
            message: this.$fanyi('SSN输入错误'),
            trigger: 'blur',
          },
        ],
        company: [
          { required: true, message: this.$fanyi('不能为空'), trigger: 'blur' },
        ],
      },
    }
  },

  created() {
    this.useraddressList()
  },
  methods: {
    handleClose(done) {
      this.$confirm(this.$fanyi('确认关闭?'), { customClass: 'message-logout' })
        .then((_) => {
          this.form = {
            type: this.type,
            company: '',
            zip_code: '',
            address: '',
            city: '',
            state: '',
            contacts: '',
            mobile: '',
            tel: '',
            email: '',
            cnpj: '',
            mark: '',
            acquiescent: 0,
            isAcquiescent: false,
          }
          done()
        })
        .catch((_) => { })
    },
    // 添加地址
    useraddressAdd() {

      this.form.isAcquiescent
        ? (this.form.acquiescent = 1)
        : (this.form.acquiescent = 0)

      this.$refs.form.validate((valid) => {
        if (valid) {

          this.$api.useraddressAdd(this.form).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
            this.$message.success(this.$fanyi(res.msg))
            this.useraddressList()
            this.dialogVisible = false
          })
        }
      })
    },
    // 编辑地址
    useraddressUpdate() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
        } else {
          return false
        }
      })
      this.form.isAcquiescent
        ? (this.form.acquiescent = 1)
        : (this.form.acquiescent = 0)
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.useraddressUpdate(this.form).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
            this.$message.success(this.$fanyi(res.msg))
            this.useraddressList()
            this.dialogVisible = false
          })
        }
      })
    },
    // 打开新增地址弹窗
    openAddAddressWin() {
      this.dialogVisible = true
      this.key = Math.random()
        .toString(36)
        .slice(-8)
      this.form = {
        type: this.type,
        company: '',
        zip_code: '',
        address: '',
        city: '',
        state: '',
        mobile: '',
        tel: '',
        email: '',
        cnpj: '',
        mark: '',
        acquiescent: 0,
        isAcquiescent: false,
      }
      this.isAdd = true
      this.title = this.$fanyi('新增地址')
    },
    // 显示编辑框
    edit(item) {
      item.isAcquiescent = item.acquiescent
      let data = JSON.stringify(item)
      this.form = JSON.parse(data)
      this.title = this.$fanyi('编辑地址')
      this.isAdd = false
      this.dialogVisible = true
      this.key = Math.random()
        .toString(36)
        .slice(-8)
    },
    // 删除
    useraddressDelete() {
      let msg = this.$fanyi('是否删除当前地址')
      this.$confirm(
        msg,
        this.$fanyi('提示'),
        {
          confirmButtonText: this.$fanyi('确定'),
          cancelButtonText: this.$fanyi('取消'),
          type: 'warning',
          center: true,
        },
        { customClass: 'message-logout' }
      )
        .then(() => {
          this.$api.useraddressDelete({ id: this.form.id }).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
            this.$message.success(this.$fanyi('成功删除地址'))
            this.useraddressList()
            this.dialogVisible = false

            // }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: this.$fanyi('删除取消'),
          })
        })
    },

    // 获取数据
    useraddressList() {
      this.loading = true
      this.consignee = []
      this.importer = []
      this.$api.useraddressList().then((res) => {
        this.loading = false
        if (res.data.consignee) {
          this.consignee = res.data.consignee
        }
        if (res.data.importer) {
          this.importer = res.data.importer
        }
      })
    },
  },
}
</script>
<style lang="scss">
.message-logout {
  .el-message-box__btns {
    button {
      border-radius: 4px !important;

      &:last-child {
        background-color: #ff730b;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import '../../../../css/mixin';

#deliverySite {
  width: 1180px;
  background-color: #f8f7f7;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  overflow: hidden;
  min-height: 200px;

  .dianhu {
    display: flex;

  }

  .quhao {
    // display: inline-block;
    width: 58px !important;
    height: 45px !important;

    margin-right: 10px;


    /deep/ .el-select {
      width: 58px !important;
      height: 44px;
      z-index: 999;
      left: 0;

      .el-input__inner {
        height: 44px;
        width: 58px !important;
        padding: 0 8px;


        &:focus {
          height: 44px;
        }

      }
    }
  }

  .Newaddress {
    width: 100%;
    height: 40px;
    // padding-right: 10px;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .add {
      width: 140px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #ff730b;
      text-align: center;
      line-height: 40px;
      font-weight: 400;
      color: #ff730b;
      cursor: pointer;
    }
  }

  .rowAndCenter {
    height: 50px;
    display: flex;
    align-items: center;

    h1 {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #222222;
      font-size: 16px !important;
    }
  }

  .deliverySiteHeader {
    background-color: #fff;
    padding: 20px 0 20px 30px;

    div:first-child {
      margin-right: 30px;
    }

    div {
      padding-bottom: 10px;
      cursor: pointer;
    }

    .activeType {
      color: #ff730b;
      border-bottom: 2px solid #ff730b;
    }
  }

  nav {
    display: flex;

    span {
      display: block;
      width: 130px;
      height: 50px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      border-bottom: none;
      text-align: center;
      line-height: 50px;
      cursor: pointer;

      &.active {
        background: #ffffff;
      }
    }
  }

  .con {
    background: #ffffff;
    border-radius: 6px;

    display: flex;
    flex-wrap: wrap;

    background-color: white;
    padding: 30px 30px 30px;
    padding-right: 28px;
    padding-top: 0;


    .default {
      width: 320px;
      height: 380px;
      background: #ffffff;
      border-radius: 6px;
      border: 2px solid #ff730b !important;
    }

    >.opt {
      width: 360px;
      height: 380px;
      margin-right: 20px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      margin-bottom: 10px;


      h1 {
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        position: relative;
      }

      .acquiescentDivContainer {
        max-width: 150px;
        height: 20px;
        background: #ff730b;
        border-radius: 6px 0px 6px 0px;
        font-size: 12px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 18px;
        margin-left: -20px;
        margin-top: -25px;
        border: 1px solid rgba(255, 115, 11, 0.4);

        font-size: 14px;
        box-sizing: border-box;
      }

      p {
        display: flex;
        margin-bottom: 14px;
        height: 18px;
        line-height: 18px;

        label {
          display: inline-block;
          width: 100px;
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
          text-align: left;
          color: #666666;
        }

        span {
          display: inline-block;
          height: 18px;
          width: 180px;
          text-align: left;
          display: block;
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
        }

        &.address {
          min-height: 20px;
          display: flex;

          span {
            height: 45px;
            overflow: hidden;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            -webkit-line-clamp: 2;
            /* 文本需要显示多少行 */
          }
        }
      }

      .btn {
        text-align: center;
        margin-top: 10px;
        display: flex;
        align-items: center;

        .tongbu {
          height: 40px;
          border-radius: 4px;
          font-size: 12px;
          padding: 0 10px;
          line-height: 14px;
          margin-right: 20px;
          background-color: #ff730b;
          color: #fff;

          &:nth-last-child(1) {
            margin: 0;
          }
        }

        button {
          height: 32px;
          border-radius: 4px;
          font-size: 12px;
          padding: 0 10px;
          line-height: 14px;
          margin-right: 20px;

          &:nth-last-child(1) {
            margin: 0;
          }
        }
      }
    }
  }
}

/deep/ .el-dialog {
  border-radius: 4px !important;

  .el-dialog__body {
    padding: 30px 0px !important;
  }
}

.btn {
  text-align: center;

  button {
    cursor: pointer;
    width: 60px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #608bff;
    color: #608bff;
    background: #ffff;
    display: inline-block;
    margin-bottom: 10px !important;
  }

  .reset {
    color: $subjectColor;
    background-color: #fff;
    margin-right: 40px;
  }

  .edit {
    button {
      width: 120px;
      height: 40px;
      font-size: 16px;
    }

    .del {
      background: #ff730b;
      color: #fff;
      border: none;
    }

    .save {
      color: #222222;
      background: #fff;
      border: 1px solid #e2e2e2;
    }
  }
}

.formBox {
  padding: 0 15px;



  /deep/ .el-input__inner {
    width: 380px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    height: 45px !important;
  }
}

.tel {


  /deep/.el-input__inner {

    width: 380px;
  }
}

// /deep/ .el-form-item__label{

// }
.el-message-box {
  /deep/ .el-button {
    border-radius: 0 !important;
  }
}

/deep/.el-dialog__header {
  border-bottom: 1px solid #dedede;

  .el-dialog__title {
    color: #000;
    font-weight: bold;
    font-size: 18px;
  }

  .el-icon-close {
    font-size: 22px;
    color: #000;
    font-weight: bold;
  }
}

/deep/ .el-input {
  height: 45px;
  width: 380px;
}

/deep/ .el-form-item__content {
  width: 500px !important;
  height: 28px;
}

/deep/ .el-form-item__error {
  margin-left: 22px;
  margin-top: 15px;
}

/deep/ .el-form-item {
  .el-form-item__label {
    width: 120px !important;
    text-align: right;
  }
}

/deep/ .el-select {
  width: 480px;
}

/deep/.el-checkbox__input {
  height: 16px;
}
</style>
